// custom properties
export const variable = {
  defaultFont: `Lato, sans-serif;`,
  colorPrimary: `#ff2d00;`,
  colorGray: `#374140;`,
  colorLightGray: `#777;`,
  colorLight: `#fff;`,
  colorDark: `#2a2c2b;`,
  colorLink: `#777;`,
  colorLinkHover: `#444;`,
  colorShadow: `#e8e8e8;`,
  colorBorder: `#ddd;`,
  colorLinkedin: `#0077b5;`,
  colorLimited: `rgb(219, 144, 4);`,
  colorAvailable: `rgb(9, 151, 9);`,
  colorUnavailable: `#ff2d00;`,
  maxWidth: `1140px;`,
  maxWidthInner: `940px;`,
  spacer: `40px;`,
}

import PropTypes from "prop-types"
import styled from "styled-components"
// import { device } from "./device"
// import { variable } from "../base/variables"
import React from "react"

const FooterWrap = styled.div`
  background-color: #000;
  text-align: center;
  color: #fff;
  padding: 30px 0;
  font-size: 15px;
  margin-top: 90px;
`

const Footer = () => (
  <FooterWrap>
    <footer>&nbsp;</footer>
  </FooterWrap>
)

Footer.propTypes = {
  siteTitle: PropTypes.string,
}

Footer.defaultProps = {
  siteTitle: ``,
}

export default Footer

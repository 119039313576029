import { createGlobalStyle } from "styled-components"
import { device } from "./device"
import { variable } from "./variables"

export const GlobalStyle = createGlobalStyle`
  body {
    font-family: ${variable.defaultFont};
    font-size: 16px;
    line-height: 26px;
    font-weight: 300;
    margin: 0;
    box-sizing: border-box;

    @media ${device.large} {
      font-size: 20px;
      line-height: 34px;
    }

    @media ${device.large} {
      &:not(.home) {
        overflow-x: hidden;
      }
    }
  }

  *,
  *:before,
  *:after {
    box-sizing: inherit;
  }

  h1,
  h2,
  h3,
  h4 {
    color: ${variable.colorDark};
    margin: 0;
    font-style: normal;
    font-weight: 300;
  }

  h1 {
    font-size: 2rem;
    line-height: 2.4rem;
    margin: 2rem 0;
    font-weight: 700;

    @media ${device.medium} {
      font-size: 3rem;
      line-height: 3.4rem;
      margin: 4rem 0;
    }
  }

  h2 {
    font-size: 1.6rem;
    line-height: 2.2rem;
    margin: 0 0 31px;

    @media ${device.medium} {
      font-size: 2.2rem;
    }
  }

  p {
    margin: 0 0 20px;
  }

  a {
    text-decoration: none;
    color: ${variable.colorLink};

    &:hover {
      color: ${variable.colorLinkHover};
    }
  }

  .button {
    background-color: ${variable.colorLink};
    text-align: center;
    border-radius: 2px;
    border: 0;
    padding: 12px 24px;
    font-size: .8rem;
    letter-spacing: 2px;
    text-transform: uppercase;
    font-weight: 300;
    color: ${variable.colorLight};
    cursor: pointer;
    -webkit-appearance: none;

    &:hover {
      background-color: ${variable.colorLinkHover};
      color: ${variable.colorLight};
    }
  }

  ::selection {
    background-color: #d9cb9e;
  }
`

import PropTypes from "prop-types"
import scrollTo from "gatsby-plugin-smoothscroll"
import styled from "styled-components"
import { device } from "../base/device"
import { variable } from "../base/variables"
import React from "react"

const HeaderWrap = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  width: 100%;
  padding: 20px 25px;

  @media ${device.medium} {
    top: ${variable.spacer};
  }

  header {
    margin: 0 auto;
    display: flex;
    z-index: 1;
    max-width: ${variable.maxWidth};
  }
`

const Logo = styled.div`
  font-size: 12px;
  text-transform: uppercase;
  color: ${variable.colorLight};
  font-weight: 900;
  flex: 1 1 40%;
  margin-top: 4px;

  @media ${device.medium} {
    font-size: 24px;
    margin-top: 0;
  }
`

const Nav = styled.div`
  text-align: right;
  flex: 1 1 60%;
  display: flex;
  justify-content: flex-end;
  align-items: center;

  span {
    display: none;

    @media ${device.large} {
      display: inline-block;
    }
  }

  button {
    background: none;
    border: 0;
    outline: none;
    cursor: pointer;
    color: ${variable.colorLight};
    font-weight: 900;
    font-size: 12px;
    margin: 0 0 0 8px;

    &:first-child {
      margin-left: 0;
    }

    &:hover {
      color: #ccc;
    }

    @media ${device.medium} {
      margin: 0 0 0 16px;
      font-size: 18px;
      margin: 0 0 0 ${variable.spacer};
    }
  }
`

const Header = ({ siteTitle }) => (
  <HeaderWrap>
    <header>
      <Logo>Karen Shannon</Logo>
      <Nav>
        <nav>
          <button onClick={() => scrollTo("#method")}>
            <span>Training &amp;</span> Method
          </button>
          <button onClick={() => scrollTo("#location")}>
            <span>Location &amp;</span> Fees
          </button>
          <button onClick={() => scrollTo("#contact")}>Contact</button>
        </nav>
      </Nav>
    </header>
  </HeaderWrap>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
